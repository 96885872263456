import React from 'react';
import './PrivacyPolicy.css'; // Import your CSS file here
import GoogleAdsConversionTag from './GoogleAdds';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h2 className="privacy-policy-title">Privacy Policy</h2>
      <h3 className="definition-title">Definitions</h3>
      <p>
        <span className="definition-label">Company:</span> Ringvo Software Private Limited, "Ringvo," "Bhavishyavani," "Company," "Firm," "we," "our," "us," "Service Provider," or similar terminology are all in reference to Ringvo Software Private Limited as a provider of Services for the remainder of this document.
      </p>
      <p>
        <span className="definition-label">User:</span> "Client," "user," "you," "your," or other similar terminology are all in reference to you, as the user of the website and as a recipient of our Services and resources for the remainder of this document.
      </p>
      <p>
        <span className="definition-label">Platform:</span> "Platform" or similar terminology are all in reference to the website- Ringvohearts.com or web links that the User can employ to access Services provided by the Company.
      </p>
      <p>
        <span className="definition-label">Astrologer:</span> "Astrologer," "expert," "astrology consultant," or similar terminology are all in reference to the Astrologer.
      </p>

      <h3 className="nature-title">Nature of Service</h3>
      <p>
        This is an online web platform where experienced Astrologers provide quality astrology sessions to the User.
      </p>

      <p>
        Ringvo reserves the right to add or remove products and Services from its overall offerings without prior notification. The aforementioned shall hereinafter be referred to as "Services."
      </p>

      <h3 className="age-limit-title">Persons Below The Age of 18</h3>
      <p>
        Our Services are not directed to persons under the age of 18 years. We do not knowingly solicit anyone under the age of 18 to participate independently in any of our Services.
      </p>

      <h3 className="consent-title">Consent</h3>
      <p>
        By using the platform, providing us your Personal Information or by making use of the features provided by the platform or by making a payment to Ringvo via Razorpay, you hereby provide your consent to the collection, storage, processing, disclosure, and transfer of your Personal Information in accordance with the provisions of this Privacy Policy, the User Agreement, and under the data privacy laws in India.
      </p>

      <p>
        You acknowledge that you are providing your Personal Information out of your free will, either directly to Ringvo or through a third-party or your organization. You have the option to not provide us the personal information sought to be collected. You will also have an option to withdraw your consent at any point, provided such withdrawal of consent is intimated to us in writing to operations@bvastro.com. Notwithstanding this, if you are accessing our platform through a third-party or your organization, you will have an option to withdraw your consent at any point, provided you explicitly inform the third party or your organization about such withdrawal of consent in writing, who would then inform us to take the appropriate action.
      </p>

      <p>
        If you do not provide us with your Personal Information or if you withdraw the consent at any point in time, we shall have the option to not fulfill the purposes for which the said Personal Information was sought and we may restrict your use of the platform.
      </p>

      <h className="consent-title">Personal Information</h>
      <p>To enable you to engage with our Services, we will use personal information about yourself - provided
directly to us or to a third party or your organisation - to contact or identify you, such as your name,
phone number, gender, age, your email address, and any other information that the Astrologer might
require from you. We also collect information you provide from responses, in-app inputs, assessments
or the feedback you send to us. If you communicate with us by email or phone, any information
provided in such communication may be collected as personal information (“Personal Information”).
The main reason we collect this Personal Information is to provide you a smooth, efficient and
customised experience. The collection of Personal Information also enables the user to create an
account and profile that can be used to interact with our Astrologer. You may change some of the
information that you provide through your account page at the website.
We may use your Personal Information to:
<br/>
 Identify and reach you;
<br/>
 Resolve service and billing problems via telephone or email.
<br/>
 Assist you in scheduling appointments, remind you of upcoming or follow-up appointments, as
well as cancelled appointments.
<br/>
 Provide you with further information, products and services and newsletters.
<br/>
 Better understand users’ needs and interests.
<br/>
 Run statistical research (such research will only use your information in an anonymous way
and cannot be linked back to you)
<br/>
 Detect and protect us against error, fraud, and other criminal activity.
<br/>
 Make disclosures as may be required under applicable law.
<br/>
 Improve our website in order to better serve you.
<br/>
 Allow us to better service you in responding to your customer service requests.
<br/>
 Run a contest, promotion, survey or other site.
<br/>
 Process your financial transaction in a timely manner.
<br/>

Your information is used by the Astrologer and our app algorithms to better assess your condition and
provide you with the most suitable counselling service or digital experience.

We are dedicated to maintaining the privacy and integrity of your Personal Information. If you decide
at any time that you no longer wish to receive certain communications from us, you can inform us by
writing to operations@bvastro.com.</p>

<h3 className="consent-title">Updating Personal Information</h3>
      <p>
      If your Personal Information changes, or if you need to update or correct your Personal Information or
have any grievance with respect to the processing or use of your Personal Information, for any
reason, you may send updates and corrections to us at operations@bvastro.com and we will
take all reasonable efforts to incorporate the changes within a reasonable period of time. If you
provide your Personal Information to a third-party platform from which you are using our Services,
Ringvo may not be able to make any changes to the same and you will have to contact the third-party
platform in order to update your Personal Information.
If your Personal Information is stored as part of your profile on the platform, you can update your
Personal Information from our website.
Some Personal Information, such as your answers to online assessments cannot be updated or
deleted once submitted. If you would like us to remove your records from our system, please contact
us at operations@bvastro.com and we will attempt to accommodate your request if we do not
have any legal obligation to retain such information.
Please note that we are required to retain certain information in keeping with professional standards
or by law for record maintaining purposes (including but not limited to payment history, feedback, user
information, etc.), so we will continue to store this information for a pre-specified period of time as per
applicable laws, even if you delete your account. There may also be residual information that will
remain within our databases and other records, which, irrespective of any efforts by us to delete
information, will not be removed from them.      </p>
      <h3 className="consent-title">Cookies</h3>
      <p>
      We use “cookies” to collect information and smoothen your experience on our platform. A cookie is a
small data file that we transfer to your device’s hard disk for record-keeping purposes. We use
cookies for two purposes. First, we may utilise persistent cookies to save your user credentials for
future logins to the Services. Second, we may utilise session ID cookies to enable certain features of
the Services, to better understand how you interact with the Services and to monitor aggregate usage
by users of the Services and online traffic routing on the Services. Unlike persistent cookies, session
cookies are deleted from your computer when you log off from the Services and then close your
browser.
We may work with third parties that place or read cookies on your browser to improve your user
experience. In such cases, by using the third party services through our platform, you consent to their
Privacy Policy and terms of use and agree not to hold Ringvo liable for any issues arising from such
use.

You can instruct your browser, by changing its options, to stop accepting cookies or to prompt you
before accepting a cookie from the websites you visit. If you do not accept cookies, however, you may
not be able to use all features or functionalities of the platform.      </p>
      <h3 className="consent-title">Log Data</h3>
      <p>
      When you visit the platform, our servers automatically record information that your browser sends
(“Log Data”). This Log Data may include information such as your computer’s Internet Protocol (“IP”)
address, browser type, device name, operating system version, configuration of the app when
accessing the Platform, the webpage you were visiting before you came to our Services, pages of our
platform and Services that you visit, the time spent on those pages, information you search for on our
Services, access times and dates, and other statistics. We use this information to analyse trends,
administer the site, track your location, gather broad demographic information for aggregate use,
increase user-friendliness and tailor our Services to better suit your needs.      </p>
      <h3 className="consent-title">Confidentiality</h3>
      <p>
      The Astrologer will take all reasonable precautions to protect confidential information obtained
through or stored in any medium.
If there is a threat to the well-being of the User themselves or to someone else (cases involving harm
to self or harm to others), the Astrologer will be obligated to share information with the Company and
others, including emergency contacts, and other people deemed fit and essential to maintain safety,
including but not limited to family, friends and law enforcement authorities, or to comply with legal
requirements.
If the Astrologer receives knowledge of any criminal activity, including present or past abuse of
minors, senior citizens or disabled persons, they will be obligated to share information with law
enforcement authorities, and to comply with all legal requirements. In the event that you are required
to seek a consultation from a mental health or medical professional, clinically significant information
can be shared with the relevant professional with your consent. All reasonable attempts will be made
to inform you before any information is shared with a third party outside Ringvo .
Except for the reasons outlined above, the Personal Information shared on Ringvo will only be shared
with others after permission has been granted by you orally or by way of email/letter/fax.      </p>


      <h3 className="consent-title">Indemnification</h3>
      <p>
       The User agrees to indemnify Ringvo for any breach in confidentiality of the User’s Personal
Information.
<br/>
 If the User accesses Ringvo through a third-party platform the User indemnifies Ringvo
against any data breaches that occur due to any acts of commission or omission from the
third-party platform.
<br/>
 To the fullest extent of the law, the User shall indemnify, defend and hold harmless Ringvo, its
officers, employees, agents, representatives, consultants, and contractors from and against

any and all loss, costs, penalties, fines, damages, claims, expenses (including attorney’s
fees) or liabilities arising out of, resulting from, or in connection with the services.  <br/>    </p>


      <h3 className="consent-title">Third Party Disclosure</h3>
      <p>
      Ringvo does not sell or trade your Personal Information to third parties unless we provide you with
advance notice. However, this does not apply to any storage or transfer to and from server/website
hosting partners and other parties who assist us in operating our platform, conducting our business,
or servicing you. We may also release your information when we believe release is appropriate to
comply with the law, enforce our site policies, or protect ours or others’ rights, property, or safety.
However, visitor information that is not personally identifiable may be provided to other parties for
marketing, advertising, or other uses.      </p>


      <h3 className="consent-title">Security</h3>
      <p>
      We employ administrative, physical, and technical measures designed to safeguard and protect
information under our control from unauthorised access, use, and disclosure. When we collect,
maintain, access, use, or disclose your Personal Information, we will do so using systems and
processes consistent with industry standards in information privacy and security. None of the call or
video sessions are recorded.
In spite of the security measures undertaken by us, we strongly discourage you from posting your
Personal Information in forums, comments or any other publicly accessible places on our platform.
Ringvo shall not be held responsible for use or misuse of any information pertaining to or shared by
the User with relation to its Services. The User will not hold Ringvo liable for any issue related to data
storage and/or security.
It is your responsibility to ensure the privacy and security of your email account and phone messages
so they cannot be accessed by third-party. Ringvo shall not be liable for any breach in confidentiality,
should your email or text messages be accessed by a third-party, with or without your consent.      </p>


      <h3 className="consent-title">Links</h3>
      <p>
      The platform may contain links to other third party sites. The third party sites are not necessarily
under the control of Ringvo. Please note that Ringvo is not responsible for the privacy practices of
such third party sites. Ringvo encourages you to be aware when you leave the platform and to read
the privacy policies of each and every third party site that collects Personal Information. If you decide
to access any of the third-party sites linked to the platform, you do this entirely at your own risk. Any
links to any partner websites is the responsibility of the linking party, and Ringvo shall not be
responsible for notification of any change in name or location of any information on the websites.
Ringvo is not responsible for the use of any Personal Information that you voluntarily disclose through
a forum or through the platform.
PAYMENT GATEWAY- RAZORPAY

When you subscribe to our services, the payment is processed through Razorpay which stores your
credit/debit card data. It is encrypted through the Payment Card Industry Data Security Standard
(PCI-DSS). The payment gateways shall be responsible for the financial transactions on Ringvo,
including accepting your payment, renewal process and refund (if applicable). In this context, they will
be responsible for collecting relevant financial information from you. We encourage you to review the
privacy policies of the third-party providers before you use the third-party software. For more insight,
you may also want to read the below mentioned policies of Razorpay.
https://razorpay.com/privacy/.      </p>



      <h3 className="consent-title">Changes To Policy</h3>
      <p>
      Ringvo reserves the right to change or remove any part of the Privacy Policy without notice or liability
to any third party. In the event there are significant changes in the way we treat your Personal
Information, or in the Privacy Policy, we may take reasonable effort, but are in no way obligated, to
display a notice on the website or send you an email, so that you may review the changed terms prior
to continuing to use the platform.
As always, if you object to any of the changes to our terms, and you no longer wish to use the
platform, you can contact Ringvo to discontinue using our platform and deactivate your account by
contacting us at support@Ringvohearts.com
Unless stated otherwise, our current Privacy Policy applies to all information that Ringvo has about
you and your account. Using the Services on the websites, or accessing the websites, after a notice
of change has been sent to you or published on our website shall constitute your consent to the
changed terms.      </p>
      <h3 className="consent-title">Grievance Redressal</h3>
      <p>
      To address the grievances of the users, Ringvo has set up a Grievance Redressal Forum. In case you
are dissatisfied with any aspect of our Services, you may contact our Grievance Redressal Officer, Mr.
Gaurav Support at support@Ringvohearts.com. We assure you a time-bound response not exceeding
one month from the date of your complaint.      </p>
      <h3 className="consent-title">Governing Law, Settlement of Disputes and Jurisdiction</h3>
      <p>
      Any dispute or claim relating to it, its enforceability or its termination under these Terms and
Conditions shall be governed by the Arbitration and Conciliation Act, 1996 by a sole arbitrator
appointed by Ringvo. The arbitration proceedings shall be held in English language at Kolkata. The
courts at Kolkata shall have exclusive jurisdiction over any disputes relating to the subject matter
herein.      </p>
      <h3 className="consent-title">Contact Information</h3>
      <p>
      E-mail: support@Ringvohearts.com      </p>


      <GoogleAdsConversionTag/>

    </div>
  );
};

export default PrivacyPolicy;
