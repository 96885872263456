import React from 'react'
import './PrivacyPolicy.css'; // Import your CSS file here
import GoogleAdsConversionTag from './GoogleAdds';

const Aboutus = () => {
  return (
    <div className="privacy-policy-container">
        <h2 className="privacy-policy-title">About Us</h2>

      <p>
      Bhavishyavani is a new-age, scientific endeavour to enrich the modern realm of astro-tech with the venerated wisdom of ancient Vedic traditions. Steadfastly rooted in proven methods and processes, Bhavishyavani envisages the creation of a safe space for you to explore the cosmic influences on their lives, so that you can make better and informed choices when taking critical life decisions. We are proud to be associated with world-renowned astrologer Ms. Poonam Dutta whose treasure trove of healing and guiding experience can unlock the secrets of your birth chart, help you transcend your mental boundaries and set you on an elevated life path.

   </p>
      {/* Add more pricing details here */}
      <GoogleAdsConversionTag/>

    </div>
  )
}

export default Aboutus