import React from 'react';
import './PrivacyPolicy.css'; // Import your CSS file here
import GoogleAdsConversionTag from './GoogleAdds';

const Pricing = () => {

  
  return (
<div className="privacy-policy-container">
        <h2 className="privacy-policy-title">Pricing Details</h2>

      <p>
      We have customised pricing for our different services. The details are provided  along with the type of the services mentioned in the Offerings which are available on the pre-booking of the Sessions. The pricing mentioned in the website is for the particular session and the fixed slot mentioned in the Offerings , however in the event of any technical glitch the same can be addressed or rescheduled by contacting our Support Team.

We are committed to reach and serve the maximum number of people and therefore we have kept the best possible prices to help our customers to get valuable insights on Astrology and allied services with personal consultation.
However our prices are not subject to the competitor's pricing and any other external influence.

Our services are offered for Sale from Ringvo Software Pvt. Ltd. and we reserve the right to refuse to sell to any person whom we believe may be misusing the service.

   </p>
      {/* Add more pricing details here */}
      <p>
        Please feel free to reach us at <a href="mailto:Operations@bhavishyavanitech.com">Operations@bhavishyavanitech.com</a>
      </p>
      <GoogleAdsConversionTag/>

    </div>
  );
}

export default Pricing;
