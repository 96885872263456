import React, { useState, useEffect } from 'react';
import './seminarPopup.css';
import Lottie from 'react-lottie';
import animation from '../welcomeAnimation/BV zodiac animation.json';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import GoogleAdsConversionTag from './GoogleAdds';
import ReactGA from 'react-ga'; // Import ReactGA
ReactGA.initialize('AW-11190286711')
const Seminarpopup = ({ closeModal }) => {
  const [showOtpInput, setShowOtpInput] = useState("onabout");
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [otpExpiresIn, setOtpExpiresIn] = useState(180); // 3 minutes in seconds
  const Navigate = useNavigate();
  const [isBookingSuccessful, setIsBookingSuccessful] = useState(false);
  const [showCustomPopup, setShowCustomPopup] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false)

  


  // Function to decrement the OTP timer
  const decrementOtpTimer = () => {
    if (otpExpiresIn > 0) {
      setOtpExpiresIn(otpExpiresIn - 1);
    }
  };

  useEffect(() => {
    handleFormSubmission()
    // Start the timer when the component mounts
    const timer = setInterval(decrementOtpTimer, 1000);

    // Clear the timer when the component unmounts
    return () => clearInterval(timer);
  }, [otpExpiresIn]); // Add otpExpiresIn as a dependency




  function handleFormSubmission() {
    // Your form submission logic here
  
    // Trigger the conversion event
    ReactGA.event('event', 'conversion', {
      'send_to': 'AW-11190286711/Bb9zCK2B-ewYEPfy-Ncp', // Use your Conversion ID and Label here.
    });
  }
  const handleEmailSubmit = async () => {
    setIsLoading(true);

    try {
      const response = await fetch('https://flutrrhearts.com/api/send-otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });

      const data = await response.json();

      if (data.message) {
        setShowOtpInput('verifyOtp');
        setOtpExpiresIn(180); // Reset the timer when a new OTP is sent
      } else {
        console.error(data.error);
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOtpVerification = async () => {
    setIsLoading(true);

    try {
      const response = await fetch('https://flutrrhearts.com/api/verify-otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, otp })
      });

      const data = await response.json();

      sessionStorage.setItem('uniqueId', data.user.uniqueId);
      sessionStorage.setItem('email', data.user.email);

      if (data.message) {
        // Navigate('/book-session');
        setShowOtpInput('paymentDetails')
        // proceedPayment()
      } else {
        alert('Error verifying OTP:');
        console.error(data.error);
      }
    } catch (error) {
      alert('Error verifying OTP:');
      console.error('Error verifying OTP:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const loaderOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };













  const handleBookingClick = async () => {
    setShowAnimation(true)
    const uniqueId = sessionStorage.getItem('uniqueId');
    const email = sessionStorage.getItem('email');


    // console.log(uniqueId,email)
    try {
      const response = await axios.post('https://flutrrhearts.com/api/book-webinar', {
        uniqueId,
        email,
      });

      const { success, booking } = response.data;

      if (success) {
        // Booking successful
        console.log('Booking successful')
        setShowCustomPopup(false)
        setIsBookingSuccessful(true);
        setShowAnimation(false)

      } else {
        // setIsBookingError(true);
      }
    } catch (error) {
      console.error('Error booking session:', error);
      // setIsBookingError(true);
    }
  };

  const closeCustomPopuppp = async () => {
    setIsBookingSuccessful(false)
    window.location.reload();

  }
  const proceedPayment = async (Fees) => {

    //////we have to check open slot first

    const res = await new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }

    const orderData = await axios.post(`https://flutrrhearts.com/api/order-webinar`).then((resposne) => {
      // console.log(resposne)
      const { amount, id: order_id, currency } = resposne.data;
      // console.log({amount, id: order_id, currency})


      const amountInRupees = Math.round(Number(amount) / 100);

      // Calculate base amount and GST
      const baseAmount = Math.round(amountInRupees / 1.18);  // This gives you the original amount before 18% GST
      const gstAmount = amountInRupees - baseAmount;



      const options = {
        key: "rzp_live_TIZViHaVui1Y5G",
        amount: amount.toString(),
        currency: currency,
        name: 'flutrr digimate pvt. ltd',
        description: `Seminar(18% GST Included) INR 999.00`,
        order_id: order_id,
        handler: async function (response) {
          const { razorpay_order_id, razorpay_payment_id, razorpay_signature } = response;
          const paymentVerificationResponse = await axios.post(`https://flutrrhearts.com/api/verify-payment`, {
            razorpay_order_id,
            razorpay_payment_id,
            razorpay_signature
          }).then(async (result) => {
            console.log(result)
            if (result.data.status == 'success') {
              // alert('Payment successful!');
              await handleBookingClick()
            } else {
              alert('Payment failed!');
            }
          })
        }
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();


    })
  };


  const closeCustomPopup = () => {
    setShowCustomPopup(false);
  };


  const onabout = async () => {
    setShowOtpInput("onEnterEmail")
  }

  return (
    <>
      <div className="modal">
        <div className="modal-content">
          <span className="close-btn" onClick={closeModal}>&times;</span>

          {isLoading ? (
            <Lottie options={loaderOptions} height={100} width={100} />
          ) : showOtpInput === 'onEnterEmail' ? (
            <>
              <h2>Verify Email ID</h2>
              <label>Email Address</label>
              <input type="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} />
              <button onClick={handleEmailSubmit}>Continue with email</button>
            </>
          ) : showOtpInput === 'onabout' ? (
            <>
              <h2>Join Seminar</h2>
              <p>Transforming your life by unlocking cosmic secrets is just Rs. 499 away!</p>
              {/* <label>Enter Verification Code</label> */}
              {/* <input type="text" placeholder="Enter verification code" maxLength="6" onChange={(e) => setOtp(e.target.value)} />
        <p>OTP will expire in {otpExpiresIn} seconds</p> */}
              <button onClick={onabout}>Continue</button>
            </>
          ) :showOtpInput==='verifyOtp' ? (
            <>
              <h2>Verify Email OTP</h2>
              <p>To continue, please enter the 6-digit login code we just sent to {email}</p>
              <label>Enter Verification Code</label>
              <input type="text" placeholder="Enter verification code" maxLength="6" onChange={(e) => setOtp(e.target.value)} />
              <p>OTP will expire in {otpExpiresIn} seconds</p>
              <button onClick={handleOtpVerification}>Continue</button>
            </>
          ):showOtpInput==='paymentDetails' ? (
                  <>
   <div className="custom-popup-overlay">
  <div className="custom-popup">
    <h2>Payment Details</h2>
    <div className="custom-ticket">
      <div className="subdiv">
        <p><strong>Seminar(18% GST Included):</strong></p>
        <p className="original-price">INR 999.00</p>
      </div>
      <div className="subdiv">

      <p><strong>Early Bird Offer(18% GST Included):</strong> </p> {/* Mention the Early Bird Offer */}
      <p>INR 499.00</p>
      </div>

      <div className="subdiv">
        <p><strong>Amount:</strong></p>
        <p>INR 499.00</p> {/* Display the offer price as 499 */}
      </div>
    </div>
    <button className="custom-proceed-payment-btn" onClick={() => { setShowOtpInput(null); proceedPayment(); }}>
      Proceed to Payment
    </button>
    <p>*Prices are all GST (18%) Included</p>
    <span className="custom-close-popup" onClick={closeCustomPopup}>&times;</span>
  </div>
</div>

            </>
          ):<>
          <p>Please wait</p>
          <Lottie options={loaderOptions} height={100} width={100} />
          </>
        }

        </div>
      </div>

      
      {isBookingSuccessful &&(

<div className="confirmation-popup-overlay">
  <div className="confirmation-popup">
    <h2 className="confirmation-title">Seminar Confirmation</h2>
    <p className="confirmation-info"><strong>Astrologer Name:</strong> Poonam Dutta</p>
    <p className="confirmation-info"><strong>Appointment Date & Time:</strong> 26th Nov 11AM TO 2PM</p>
    <div className="confirmation-venue">
      <p><strong>Venue Details:</strong></p>
      <p>Address & Check your mail for further details</p>
      <p className="confirmation-decorated-text">Check your registration mail</p>
    </div>
    <button className="confirmation-close-popup" onClick={()=>{closeCustomPopuppp()}}>&times;</button>
  </div>
</div>


)}

      {showCustomPopup && (
        <div className="custom-popup-overlay">
          <div className="custom-popup">
            <h2>Payment Details</h2>
            <div className="custom-ticket">
      <div className="subdiv">
        <p><strong>Seminar(18% GST Included):</strong></p>
        <p className="original-price">INR 9999.00</p>
      </div>
      <div className="subdiv">
        <p><strong>Early Bird Offer(18% GST Included):</strong></p> {/* Mention the Early Bird Offer */}
        <p>INR 3333.00</p>
      </div>
      <div className="subdiv">
        <p><strong>Amount:</strong></p>
        <p>INR 3333.00</p> {/* Display the offer price as 499 */}
      </div>
    </div>
            <button className="custom-proceed-payment-btn" onClick={proceedPayment}>
              Proceed to Payment
            </button>
            <p>*Prices are all GST (18%) Included</p>
            <span className="custom-close-popup" onClick={closeCustomPopup}>&times;</span>
          </div>
        </div>

      )}


{showAnimation && (
        <>

          <div className="confirmation-popup-overlay">
            <div className="confirmation-popup">
              <p>Please Wait...</p>
              <Lottie options={loaderOptions} height={100} width={100} />
            </div>
          </div>
        </>

      )}
<GoogleAdsConversionTag/>

    </>
  );
}

export default Seminarpopup;
