import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Navbar from './components/navbar';
import Intro from './components/intro';
import About from './components/About';
import BhavishyawaniLogo from './images/Bhavishyavani.svg';
import Footer from './components/Footer';
import BookingSession from './components/BookingSession';
import PricingDetails from '../src/components/Pricing'
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions  from './components/TermsAndConditions';
import AboutUs from './components/Aboutus'
import {trackPageView} from '../src/components/gtag'
import closed from '../src/images/closed.jpg'
function App() {

  useEffect(()=>{
    trackPageView()
  })
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<>
        <Intro />
        <About />
        </>} />
        <Route path="/book-session" element={<BookingSession />} />
        <Route path="/PricingDetails" element={<PricingDetails />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/termsAndConditions" element={<TermsAndConditions />} />
        <Route path="/AboutUs" element={<AboutUs />} />



      </Routes>
      <Footer />
    </Router>
    // <img className='closed' src={closed} alt='closed'/>

  );
}

export default App;
