import React from 'react'
import BhavishyawaniLogo from '../images/Bhavishyavani.svg';

const Footer = () => {
  return (
    <>
        <footer className="footer">
    <div className="footer-section">
        <img src={BhavishyawaniLogo} alt='brand-logo'/>
        <h4>Where the stars and planets show your future</h4>
    </div>
    <div className="contact-follow-container">
    <div className="contact-us">
        <h3>Contact Us</h3>
        <p>operations@bvastro.com</p>
        <p>+91 9147334845</p>
        
    </div>
    <div className="follow-us">
        <h3>Follow us</h3>
        <a href="https://www.instagram.com/bv_astro/">Instagram</a>
        <a href="https://www.facebook.com/profile.php?id=61552262880543&mibextid=LQQJ4d">Facebook</a>
        <a href="https://www.linkedin.com/company/bvastro/">LinkedIn</a>
    </div>
</div>

    <div className="footer-section">
        <h3>Company</h3>
        <ul>
            <li><a href="/PrivacyPolicy">Privacy Policy</a></li>
            <li><a href="/termsAndConditions">Terms and Conditions</a></li>
            <li><a href="AboutUs">About Us</a></li>
            {/* <li><a href="#">Cancellation/Refund Policy</a></li> */}
            <li><a href="/PricingDetails">Price Details</a></li>
        </ul>
    </div>
    <div className="footer-bottom">
        <p>Copyright © Bhavishyavani Private Limited 2023</p>
    </div>
</footer>
    </>
  )
}

export default Footer