import React from 'react';
import './PrivacyPolicy.css'; // Import your CSS file here
import GoogleAdsConversionTag from './GoogleAdds';

const TermsAndConditions = () => {
  return (
    <div className="privacy-policy-container">
      <h2 className="privacy-policy-title">Terms and Conditions</h2>
      <h3 className="definition-title">Definitions</h3>
      <p>
        <span className="definition-label">Company:</span> Ringvo Software Private Limited, "Ringvo," "Bhavishyavani," "Company," "Firm," "we," "our," "us," "Service Provider," or similar terminology are all in reference to Ringvo Software Private Limited as a provider of Services for the remainder of this document.
      </p>

      <h3 className="age-limit-title">Persons Below The Age of 18</h3>
      <p>
        Our Services are not directed to persons under the age of 18 years. We do not knowingly solicit anyone under the age of 18 to participate independently in any of our Services.
      </p>

      <h3 className="consent-title">Consent</h3>
      <p>
        By using the platform, providing us your Personal Information or by making use of the features provided by the platform or by making a payment to Ringvo via Razorpay, you hereby provide your consent to the collection, storage, processing, disclosure, and transfer of your Personal Information in accordance with the provisions of this Privacy Policy, the User Agreement, and under the data privacy laws in India.
      </p>

      <h3 className="cookies-title">Cookies</h3>
      <p>
        We use “cookies” to collect information and smoothen your experience on our platform. A cookie is a small data file that we transfer to your device’s hard disk for record-keeping purposes. We use cookies for two purposes. First, we may utilise persistent cookies to save your user credentials for future logins to the Services. Second, we may utilise session ID cookies to enable certain features of the Services, to better understand how you interact with the Services and to monitor aggregate usage by users of the Services and online traffic routing on the Services. Unlike persistent cookies, session cookies are deleted from your computer when you log off from the Services and then close your browser.
      </p>

      <h3 className="log-data-title">Log Data</h3>
      <p>
        When you visit the platform, our servers automatically record information that your browser sends (“Log Data”). This Log Data may include information such as your computer’s Internet Protocol (“IP”) address, browser type, device name, operating system version, configuration of the app when accessing the Platform, the webpage you were visiting before you came to our Services, pages of our platform and Services that you visit, the time spent on those pages, information you search for on our Services, access times and dates, and other statistics. We use this information to analyse trends, administer the site, track your location, gather broad demographic information for aggregate use, increase user-friendliness and tailor our Services to better suit your needs.
      </p>

      <h2 className="terms-and-conditions-title">Terms and Conditions</h2>
      <h3 className="definition-title">Definitions</h3>
      <p>
        <span className="definition-label">Company:</span> Ringvo Software Private Limited, "Ringvo," "Bhavishyavani," "Company," "Firm," "we," "our," "us," "Service Provider," or similar terminology are all in reference to Ringvo Software Private Limited as a provider of Services for the remainder of this document.
      </p>

      <h3 className="nature-title">Nature of Service</h3>
      <p>
        This is an online web platform where experienced Astrologers provide quality astrology sessions to the User.
      </p>

      <p>
        Ringvo reserves the right to add or remove products and Services from its overall offerings without prior notification. The aforementioned shall hereinafter be referred to as “Services.”
      </p>

      <h3 className="age-limit-title">Persons Below The Age of 18</h3>
      <p>
        Our Services are not directed to persons under the age of 18 years. We do not knowingly solicit anyone under the age of 18 to participate independently in any of our Services.
      </p>

      <h3 className="disclaimer-title">Disclaimer</h3>
      <p>
        Ringvo does not deal with medical or psychological emergencies. We are not designed to offer support in crisis situations - including when an individual is experiencing thoughts of self-harm or suicide, or is showing symptoms of severe clinical disorders such as schizophrenia and other psychotic conditions. In these cases, in-person medical intervention is the most appropriate form of help.
      </p>

      <p>
        If you feel you are experiencing any of these difficulties, or if you are considering or contemplating suicide or feel that you are a danger to yourself or to others, we would urge you to seek help at the nearest hospital or emergency room where you can connect with a psychiatrist, social worker, counsellor or therapist in person. The same applies in-case of any medical or psychological health emergency. We recommend you to involve a close family member or a friend who can offer support.
      </p>

      <h2 className="user-agreement-title">User Agreement</h2>
      <p>
        By choosing to visit and/or avail any Services and/or product and/or resource from Ringvo, the user confirms that they are above 18 years of age and are legally allowed to take decisions of their own.
      </p>
      <p>
        The user also accepts and agrees to our Terms and Conditions (hereafter referred to as “User Agreement”) and those additional terms and conditions and policies referenced herein and/or available by hyperlinks.
      </p>
      <p>
        Please read the User Agreement carefully. The terms in the User Agreement are in effect upon your using or availing Services, resources, or any product from the platform. Accessing the Service/platform on any medium, including but not limited to mobile phones, smartphones, and tablets, is also subject to the terms of the User Agreement.
      </p>
      <p>
        We reserve the right to add, modify or remove sections from the User Agreement at any point in time during the course of the agreement with no prior notice. Continuing to use the Ringvo platform, purchase of any Service, resource or product, forms an obligatory legal agreement between the user and Ringvo. Liability to review the User Agreement from time to time lies with the user. Continuation of the use of the platform after revision of the User Agreement will be deemed to be an acceptance of the revised terms.
      </p>

      <h3 className="terms-of-use-title">Terms of Use</h3>
      <p>
        During the course of the User Agreement, it is expressly stated, understood, and agreed by you (user) that you (user) shall abide by the following terms of use.
      </p>
      <p>
        a. You must be at least 18 years of age to use the Service.
      </p>
      <p>
        b. You have never been convicted under any law governing us or you.
      </p>
      <p>
        c. You are legally capable of entering into a binding contract with Ringvo.
      </p>
      <p>
        d. You are not barred from using the Service under the Indian laws or any other jurisdiction worldwide.
      </p>
      <p>
        e. You do not have any unspent convictions or are subject to any court order, relating to assault, violence, sexual misconduct, or harassment.
      </p>

      <h3 className="intellectual-property-title">Intellectual Property</h3>
      <p>
        The contents on the platform are for informational purposes only. Ringvo disclaims all liability to any person for any loss or damage caused by dependence on any part of the platform. Third-party content appearing on the platform is the property of their respective owners and Ringvo does not assert any rights in relation to the same. Such third-party content is used by Ringvo subject to the fair use provisions of Indian copyright law and applicable provisions in other jurisdictions. Ringvo asserts its copyright ownership on the remaining contents of this document. No information, content, or material from the platform and/or Services may be copied, reproduced, republished, uploaded, posted, transmitted, or distributed in any way without an express written permission from Ringvo.
      </p>

      <h3 className="payment-gateway-title">Payment Gateway - Razorpay</h3>
      <p>
        When you subscribe to our services, the payment is processed through Razorpay which stores your credit/debit card data. It is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS). The payment gateways shall be responsible for the financial transactions on Ringvo, including accepting your payment, renewal process, and refund (if applicable). In this context, they will be responsible for collecting relevant financial information from you. We encourage you to review the privacy policies of the third-party providers before you use the third-party software. For more insight, you may also want to read the below mentioned policies of Razorpay.
      </p>
      <p>
        https://razorpay.com/privacy/.
      </p>
      <p>
        Ringvo reserves the right to change any or all parts of Payment policy without notice or liability to the user or any third-party.
      </p>

      <h3 className="privacy-policy-section-title">Limitation of Liability</h3>
      <p>
        a. Incidental Damages and Aggregate Liability. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL RINGVO BE LIABLE FOR ANY INCIDENTAL, SPECIAL, CONSEQUENTIAL OR INDIRECT DAMAGES ARISING OUT OF OR RELATING TO THE USE OR INABILITY TO USE THE SERVICES, RESULT OF USING THE SERVICES INCLUDING, WITHOUT LIMITATION, DAMAGES FOR RECOMMENDATION OF THE SITE, FAILED MARRIAGE ACHIVED THROUGH THE SITE, OUTCOME OF VERIFICATION CHECKS, LOSS OR CORRUPTION OF DATA OR PROGRAMS, SERVICE INTERRUPTIONS AND PROCUREMENT OF SUBSTITUTE SERVICES, EVEN IF RINGVO KNOWS OR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES WILL RINGVO BE LIABLE FOR ANY LIQUIDATED OR PUNITIVE DAMAGES.
      </p>
      <p>
        b. No Liability for non-Ringvo Actions. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL RINGVO BE LIABLE FOR ANY DAMAGES WHATSOEVER, WHETHER DIRECT, INDIRECT, GENERAL, SPECIAL, COMPENSATORY, CONSEQUENTIAL, AND/OR INCIDENTAL, LIQUIDATED, PUNITIVE ARISING OUT OF OR RELATING TO RECOMMENDATION OF THE SITE, THE CONDUCT OF USER OR ANYONE ELSE IN CONNECTION WITH THE USE OF THE SERVICES, INCLUDING WITHOUT LIMITATION, BODILY INJURY, EMOTIONAL DISTRESS, FINANCIAL LOSS AND/OR ANY OTHER DAMAGES RESULTING FROM COMMUNICATIONS OR MEETINGS WITH OTHER REGISTERED USERS OF THE SERVICES OR AS A RESULT OF USING THE SERVICES. THIS INCLUDES ANY CLAIMS, LOSSES OR DAMAGES ARISING FROM THE CONDUCT OF USERS WHO HAVE REGISTERED UNDER FALSE PRETENSES OR WHO ATTEMPT TO DEFRAUD OR HARM OTHER USERS OR FAILED MARRAIGES OR DIVORCE OF WEDDINGS CASUED UNDER THE USE OF THE SERVICES.
      </p>
      <p>
        c. New Features. From time to time, Ringvo may offer new features or tools with which its users may experiment on the Services. Such features or tools are offered solely for experimental purposes and without any warranty of any kind, and may be modified or discontinued at Ringvo's sole discretion. The provisions of this Disclaimer of Warranty section apply with full force to such features or tools.
      </p>

      <h3 className="privacy-policy-section-title">Disclaimer on Warranties</h3>
      <p>
        However, Ringvo guarantees that all communication between the user and Ringvo and / or the counsellor within the ambit of therapy shall be kept confidential, subject to compliance with applicable laws.
      </p>
      <p>
        You agree to not seek any other person’s contact information through our platform.
      </p>
      <p>
        You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libellous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website location. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third-party.
      </p>
      <p>
        We may, but have no obligation to:
      </p>
      <ul>
        <li>
          monitor, edit or remove content that we determine in our sole discretion as unlawful, offensive, threatening, libellous, defamatory, pornographic, obscene or otherwise objectionable or in violation of any party’s intellectual property or these Terms of Service.
        </li>
        <li>
          Investigate an allegation that a communication does not conform to these terms and determine in its sole discretion to remove or request the removal of the communication;
        </li>
        <li>
          Terminate a User’s access to any or all parts of the Platform upon any breach of these Terms of Service;
        </li>
        <li>
          Edit or delete any communication(s) posted on the Platform, regardless of whether such communication(s) violate these standards.
        </li>
      </ul>
      <p>
        We have no liability or responsibility to Users of the Platform or any other person or entity for performance or non-performance of the aforementioned activities.
      </p>
      <p>
        Users must note that Ringvo is an interactive Service and other users are free to post their own comments on various content of the platform. The platform publishes articles provided by various authors as well. The contents/viewpoints expressed in these comments/posts/articles etc. are their authors’. Such content does not indicate the official position of Ringvo, in any way, express or implied.
      </p>
      <h3 className="terms-and-conditions-section-title">Entire Agreement</h3>
      <p>
        The failure of us to exercise or enforce any right or provision of this User Agreement shall not constitute a waiver of such right or provision. This User Agreement and any policies or operating rules posted by us on this platform or in respect to the Services constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications, and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).
      </p>
      <h3 className="terms-and-conditions-section-title">Termination</h3>
      <p>
        The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes. This User Agreement is effective unless and until terminated by either you or us. You may terminate this User Agreement at any time by notifying us at support@Ringvohearts.com - directly, or if you are accessing the platform through a third-party, then through the respective third-party - that you no longer wish to use Ringvo’s Services, or when you cease using our platform, subject to settlement of all outstanding payments. If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of this User Agreement, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly we may deny you access to our Services (or any part thereof).
      </p>
      <h3 className="terms-and-conditions-section-title">Errors, Inaccuracies and Omissions</h3>
      <p>
        Occasionally there may be information on our platform or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product / Service descriptions, pricing, promotions, offers etc. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order). We undertake no obligation to update, amend or clarify information in the Service or on any related website /platform, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.
      </p>
      <h3 className="terms-and-conditions-section-title">Prohibited Uses</h3>
      <p>
        In addition to other prohibitions as set forth in this User Agreement, you are prohibited from using the platform or its content: for any unlawful purpose; to solicit others to perform or participate in any unlawful acts; to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; to infringe upon or violate our intellectual property rights or the intellectual property rights of others; to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; to submit false or misleading information; to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; to collect or track the personal information of others; to spam, phish, pharm, pretext, spider, crawl, or scrape; for any obscene or immoral purpose; or to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website/ platform for violating any of the prohibited uses.
      </p>

      <h3 className="terms-and-conditions-section-title">Disclaimer of Warranties; Limitation of Liability</h3>
      <p>
        <ul>
          <li>We do not guarantee, represent or warrant that your use of our Service will be uninterrupted, timely, secure or error-free.</li>
          <li>You agree to indemnify Ringvo against any breach in confidentiality. If you access Ringvo through a third-party platform, you agree that Ringvo cannot be responsible for any data breaches that occur due to any acts of commission or omission from the third-party platform.</li>
          <li>We do not warrant that the results that may be obtained from the use of the Service will be accurate or reliable.</li>
          <li>You agree that from time to time we may remove the Service (or part thereof) for indefinite periods of time or cancel the Service at any time, without notice to you.</li>
          <li>You expressly agree that your use of, or inability to use, the Service is at your sole risk. The Service and all products and Services delivered to you through the Service are (except as expressly stated by us) provided ‘as is’ and ‘as available’ for your use, and/or in keeping with Ringvo’s arrangement with your organization, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, H1, and non-infringement.</li>
          <li>In no case shall Ringvo, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, Service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the Services or any products procured using the Services, or for any other claim related in any way to your use of the Services or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the Services or any content (or product) posted, transmitted, or otherwise made available via the Service or platform, even if advised of their possibility. In all cases, our maximum liability shall be limited to the amount paid by you, if at all, for Service/product in dispute.</li>
        </ul>
      </p>

      <h3 className="terms-and-conditions-section-title">Indemnification</h3>
      <p>
        The User agrees to indemnify Ringvo for any breach in confidentiality of the User’s Personal Information. If the User accesses Ringvo through a third-party platform the User indemnifies Ringvo against any data breaches that occur due to any acts of commission or omission from the third-party platform.
        To the fullest extent of the law, the User shall indemnify, defend and hold harmless Ringvo, its officers, employees, agents, representatives, consultants, and contractors from and against any and all loss, costs, penalties, fines, damages, claims, expenses (including attorney’s fees) or liabilities arising out of, resulting from, or in connection with the services.
      </p>

      <h3 className="terms-and-conditions-section-title">Severability</h3>
      <p>
        If any provision, hereunder becomes, in whole or in part, invalid or unenforceable but would be valid or enforceable if some part of that provision was deleted, that provision shall apply with such deletions as may be necessary to make it valid. If any Court/Tribunal of competent jurisdiction holds any of the provisions, hereunder unlawful or otherwise ineffective, the remainder shall remain in full force and the unlawful or otherwise ineffective provision shall be substituted by a new provision reflecting the intent of the provision so substituted.
      </p>

      <h3 className="terms-and-conditions-section-title">Grievance Redressal</h3>
      <p>
        To address the grievances of the users, Ringvo has set up a Grievance Redressal Forum. In case you are dissatisfied with any aspect of our Services, you may contact our Grievance Redressal Officer, Mr. Gaurav Pathak at operations@bvastro.com. We assure you a time-bound response not exceeding one month from the date of your complaint.
      </p>

      <h3 className="terms-and-conditions-section-title">Governing Law, Settlement of Disputes and Jurisdiction</h3>
      <p>
        Any dispute or claim relating to it, its enforceability or its termination under these Terms and Conditions shall be governed by the Arbitration and Conciliation Act, 1996 by a sole arbitrator appointed by Ringvo. The arbitration proceedings shall be held in English language at Kolkata. The courts at Kolkata shall have exclusive jurisdiction over any disputes relating to the subject matter herein.
      </p>

      <h3 className="terms-and-conditions-section-title">Contact Information</h3>
      <p>
        E-mail: operations@bvastro.com
      </p>
      <GoogleAdsConversionTag/>

    </div>
  );
};

export default TermsAndConditions;
