import React, { useState, useEffect } from 'react';
import './seminarPopup.css';
import Lottie from 'react-lottie';
import animation from '../welcomeAnimation/BV zodiac animation.json';
import { useNavigate } from 'react-router-dom';
import GoogleAdsConversionTag from './GoogleAdds';


const Seminarpopup = ({ closeModal }) => {
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [otpExpiresIn, setOtpExpiresIn] = useState(180); // 3 minutes in seconds
    const Navigate = useNavigate();

    // Function to decrement the OTP timer
    const decrementOtpTimer = () => {
        if (otpExpiresIn > 0) {
            setOtpExpiresIn(otpExpiresIn - 1);
        }
    };

    useEffect(() => {
        // Start the timer when the component mounts
        const timer = setInterval(decrementOtpTimer, 1000);

        // Clear the timer when the component unmounts
        return () => clearInterval(timer);
    }, [otpExpiresIn]); // Add otpExpiresIn as a dependency

    const handleEmailSubmit = async () => {
        setIsLoading(true);

        try {
            const response = await fetch('https://flutrrhearts.com/api/send-otp', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            });

            const data = await response.json();

            if (data.message) {
                setShowOtpInput(true);
                setOtpExpiresIn(180); // Reset the timer when a new OTP is sent
            } else {
                console.error(data.error);
            }
        } catch (error) {
            console.error('Error sending OTP:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleOtpVerification = async () => {
        setIsLoading(true);

        try {
            const response = await fetch('https://flutrrhearts.com/api/verify-otp', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, otp })
            });

            const data = await response.json();

            sessionStorage.setItem('uniqueId', data.user.uniqueId);
            sessionStorage.setItem('email', data.user.email);

            if (data.message) {
                Navigate('/book-session');
            } else {
                alert('Error verifying OTP:');
                console.error(data.error);
            }
        } catch (error) {
            alert('Error verifying OTP:');
            console.error('Error verifying OTP:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const loaderOptions = {
        loop: true,
        autoplay: true,
        animationData: animation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close-btn" onClick={closeModal}>&times;</span>

                {isLoading ? (
                    <Lottie options={loaderOptions} height={100} width={100} />
                ) : !showOtpInput ? (
                    <>
                    <p>Life-changing remedies are just Rs. 2499 away!</p>
                        <h2>Verify Email ID</h2>
                        <label>Email Address</label>
                        <input type="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        <button onClick={handleEmailSubmit}>Continue with email</button>
                    </>
                ) : (
                    <>
                        <h2>Verify Email OTP</h2>
                        <p>To continue, please enter the 6-digit login code we just sent to {email}</p>
                        <label>Enter Verification Code</label>
                        <input type="text" placeholder="Enter verification code" maxLength="6" onChange={(e) => setOtp(e.target.value)} />
                        <p>OTP will expire in {otpExpiresIn} seconds</p>
                        <button onClick={handleOtpVerification}>Verify OTP</button>
                    </>
                )}
            </div>
            <GoogleAdsConversionTag/>

        </div>
    );
}

export default Seminarpopup;
