import React from 'react';

const GoogleAdsConversionTag = () => {
  return (
    <>
      {/* Google Ads Conversion Tracking Tag */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11190286711"></script>
      <script>
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag('js', new Date());

        gtag('config', 'AW-11190286711');
        `}
      </script>
    </>
  );
};

export default GoogleAdsConversionTag;
