import React, { useEffect, useState } from 'react';
import './BookingSession.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Razorpay from 'react-razorpay';
import Lottie from 'react-lottie';
import animation from '../welcomeAnimation/BV zodiac animation.json';
import GoogleAdsConversionTag from './GoogleAdds';
import ReactGA from 'react-ga'; // Import ReactGA
ReactGA.initialize('AW-11190286711')
const BookingSession = () => {
  const [selectedTime, setSelectedTime] = useState(null);
  const [isBookingSuccessful, setIsBookingSuccessful] = useState(false);
  const [isBookingError, setIsBookingError] = useState(false);
  const [bookedSlots, setBookedSlots] = useState([]); // Store booked slots
  const [PaymentDetails, setPaymentDetails] = useState();
  const [showCustomPopup, setShowCustomPopup] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false)

  const navigate = useNavigate();

  useEffect(() => {
    const uniqueId = sessionStorage.getItem('uniqueId');
    const email = sessionStorage.getItem('email');

    if (uniqueId.length !== 16 && !email) {
      alert('Please Login Again');
      navigate('/');
    }
    handleFormSubmission()
    // Fetch already booked slots from the server and update the `bookedSlots` state
    axios.post('https://flutrrhearts.com/api/get-booked-slots', { email })
      .then((response) => {
        const { success, bookedSlots } = response.data;
        if (success) {
          setBookedSlots(bookedSlots);
        } else {
          setIsBookingError(true);
        }
      })
      .catch((error) => {
        console.error('Error fetching booked slots:', error);
        setIsBookingError(true);
      });
  }, []);


  function handleFormSubmission() {
    // Your form submission logic here
  
    // Trigger the conversion event
    ReactGA.event('event', 'conversion', {
      'send_to': 'AW-11190286711/Bb9zCK2B-ewYEPfy-Ncp', // Use your Conversion ID and Label here.
    });
  }

  const openCustomPopup = () => {
    setShowCustomPopup(true);
  };

  const closeCustomPopup = () => {
    setShowCustomPopup(false);
  };





  const handleTimeSlotClick = (time) => {
    if (!isBookingSuccessful && !bookedSlots.includes(time)) {
      setSelectedTime(time);
    }
  };

  const handleBookingClick = async () => {
    // Check if a time slot is selected
    setShowAnimation(true)
    if (!selectedTime) {
      return;
    }

    // Send booking data to the server
    const uniqueId = sessionStorage.getItem('uniqueId');
    const email = sessionStorage.getItem('email');

    try {
      const response = await axios.post('https://flutrrhearts.com/api/book-session', {
        uniqueId,
        email,
        selectedTime,
      });

      const { success, booking } = response.data;

      if (success) {
        // Booking successful
        setShowCustomPopup(false)
        setIsBookingSuccessful(true);

        // Add the booked slot to the `bookedSlots` state
        setBookedSlots([...bookedSlots, selectedTime]);
        setShowAnimation(false)


        // Refresh the window after successful booking
        // window.location.reload();
      } else {
        // Booking failed
        setShowAnimation(false)
        setIsBookingError(true);

      }
    } catch (error) {
      console.error('Error booking session:', error);
      setIsBookingError(true);
    }
  };


  const proceedPayment = async (Fees) => {

    //////we have to check open slot first

    const res = await new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }

    const orderData = await axios.post(`https://flutrrhearts.com/api/order`).then((resposne) => {
      // console.log(resposne)
      const { amount, id: order_id, currency } = resposne.data;
      // console.log({amount, id: order_id, currency})


      const amountInRupees = Math.round(Number(amount) / 100);

      // Calculate base amount and GST
      const baseAmount = Math.round(amountInRupees / 1.18);  // This gives you the original amount before 18% GST
      const gstAmount = amountInRupees - baseAmount;


      // let rzp = new Razorpay({
      //   key_id: 'rzp_live_TIZViHaVui1Y5G',
      //   key_secret: 'caxual7A4jBMArCJi4GraBKm'
      // })
      // rzp_test_mA5Qli5ArVxrih


      const options = {
        key: "rzp_live_TIZViHaVui1Y5G",
        amount: amount.toString(),
        currency: currency,
        name: 'flutrr digimate pvt. ltd',
        description: `1-to-1 Session Fees (18% GST Included) INR 3333.00`,
        order_id: order_id,
        handler: async function (response) {
          const { razorpay_order_id, razorpay_payment_id, razorpay_signature } = response;
          const paymentVerificationResponse = await axios.post(`https://flutrrhearts.com/api/verify-payment`, {
            razorpay_order_id,
            razorpay_payment_id,
            razorpay_signature
          }).then(async (result) => {
            console.log(result)
            if (result.data.status == 'success') {
              // alert('Payment successful!');
              await handleBookingClick()
            } else {
              alert('Payment failed!');
            }
          })
        }
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();


    })
  };



  const closeCustomPopuppp = async () => {
    setIsBookingSuccessful(false)
    window.location.reload();

  }


  const loaderOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };



  return (
    <>
      <div className="booking-container">
        <h2>Book an Online Consultation</h2>
        <p><strong>Session Date:</strong> November 26, 2023</p>
        <h3>Select Your Session Timing:</h3>
        <div className="session-timings">
          {["11.00 AM","11.30 AM","01:00 PM","01:30 PM","02:00 PM","02.30 PM","03:00 PM", "03:30 PM", "04:00 PM", "04:30 PM", "05:00 PM", "05:30 PM", "06:00 PM", "06:30 PM", "07:00 PM", "07:30 PM"].map(time => (
            <button
              key={time}
              className={`time-slot ${selectedTime === time ? 'selected' : ''} ${isBookingSuccessful ? 'disabled' : ''} ${bookedSlots.includes(time) ? 'booked' : ''}`}
              onClick={() => handleTimeSlotClick(time)}
              disabled={isBookingSuccessful || bookedSlots.includes(time)}
            >
              {time}
            </button>
          ))}
        </div>
        <button
          className={`continue-btn ${isBookingSuccessful || !selectedTime || bookedSlots.includes(selectedTime) ? 'disabled' : ''}`}
          onClick={openCustomPopup}
          disabled={isBookingSuccessful || !selectedTime || bookedSlots.includes(selectedTime)}
        >
          {isBookingSuccessful ? 'Booking Successful' : 'Continue Booking'}
        </button>
        {isBookingError && (
          <p className="booking-error">Booking failed. Please try again later.</p>
        )}
      </div>


      {showCustomPopup && (
        <div className="custom-popup-overlay">
          <div className="custom-popup">
            <h2>Payment Details</h2>
            <div className="custom-ticket">
      <div className="subdiv">
        <p><strong> Online Consultation(18% GST Included):</strong></p>
        <p className="original-price">INR 4999.00</p>
      </div>
      <div className="subdiv">
        <p><strong>Early Bird Offer(18% GST Included):</strong></p> {/* Mention the Early Bird Offer */}
        <p>INR 2499.00</p>
      </div>
      <div className="subdiv">
        <p><strong>Amount:</strong></p>
        <p>INR 2499.00</p> {/* Display the offer price as 499 */}
      </div>
    </div>
            <button className="custom-proceed-payment-btn" onClick={proceedPayment}>
              Proceed to Payment
            </button>
            <p>*Prices are all GST (18%) Included</p>
            <span className="custom-close-popup" onClick={closeCustomPopup}>&times;</span>
          </div>
        </div>

      )}



      {isBookingSuccessful && (

        <div className="confirmation-popup-overlay">
          <div className="confirmation-popup">
            <h2 className="confirmation-title">1-to-1 Session Confirmation</h2>
            <p className="confirmation-info"><strong>Astrologer Name:</strong> Poonam Dutta</p>
            <p className="confirmation-info"><strong>Appointment Date & Time:</strong> 26th November, {selectedTime}</p>
            <div className="confirmation-venue">
              <p><strong>Venue Details:</strong></p>
              <p>Address & Check your mail for further details</p>
              <p className="confirmation-decorated-text">Check your registration mail</p>
            </div>
            <button className="confirmation-close-popup" onClick={() => { closeCustomPopuppp() }}>&times;</button>
          </div>
        </div>


      )}


      {showAnimation && (
        <>

          <div className="confirmation-popup-overlay">
            <div className="confirmation-popup">
              <p>Please Wait...</p>
              <Lottie options={loaderOptions} height={100} width={100} />
            </div>
          </div>
        </>

      )}


<GoogleAdsConversionTag/>


    </>
  );
}

export default BookingSession;
